import Button from "../../button/button";
import "./packageFrame1.scss";
import { useContext } from "react";
import AlertModal, { alertModalTypes } from "../../modals/alert-modal/alert-modal";
import { ModalContext } from "../../../utils/modal/modalContext";

function PackageFrame1({ classes = "", offer = {}, onClick = () => {}, onSuccess = () => {} }) {
  const { handleModal } = useContext(ModalContext);

  const openExtraOptionsModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.ExtraOptions}
          className="modal-wider"
          title="Alege extra opțiuni"
          successLabel="Comandă"
          cancelLabel="Anulează"
          extraData={{ offer: offer }}
          onDismiss={() => {}}
          onSuccess={onSuccess}></AlertModal>
      );
    });
  };

  return (
    <div className="frame-container">
      <div className={`${classes} package-frame package-frame-1`} onClick={onClick}>
        <div className="left-frame">
          <img
            className="full-left d-block d-md-none"
            alt={offer?.title}
            src={require("./../../../assets/frame-borders/frame-1/left-simple.png")}
          />
          <img
            className="full-left d-none d-md-block"
            alt={offer?.title}
            src={require("./../../../assets/frame-borders/frame-1/left.png")}
          />
        </div>

        <div className="middle-frame">
          <div className="full-middle-1" />
          <div className="full-middle-2" />

          <div className="middle-title package-title">{offer?.title}</div>

          <div className="middle-content">
            <div className="package-description">
              {offer?.points.map((point, index) => {
                return (
                  <div className="bullet-point-left" key={"package-offer-" + offer?.title + "-point-" + index}>
                    {point?.content}
                  </div>
                );
              })}
            </div>

            {/* <div className="package-extra text-center" onClick={openExtraOptionsModal}>
              + Alte opțiuni la alegere
            </div> */}
            <div className="package-actions">
              <Button
                scale="0.8"
                onClick={openExtraOptionsModal}
                type="outline"
                content={
                  <div className="d-flex flex-wrap justify-content-center gap-1">
                    <div>{`Alege alte opțiuni`}</div>
                  </div>
                }
              />
              <Button
                scale="0.8"
                onClick={() => onSuccess(offer, false)}
                content={
                  <div className="d-flex flex-wrap justify-content-center gap-1">
                    <div>{`Comandă -`}</div>
                    <div>{`${offer?.price}`}</div>
                    <div>{`${offer?.currency}`}</div>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <div className="right-frame">
          <img
            className="full-right d-block d-md-none"
            alt={offer?.title}
            src={require("./../../../assets/frame-borders/frame-1/right-simple.png")}
          />
          <img
            className="full-right d-none d-md-block"
            alt={offer?.title}
            src={require("./../../../assets/frame-borders/frame-1/right.png")}
          />
        </div>
      </div>
    </div>
  );
}

export default PackageFrame1;
