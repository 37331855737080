import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import SimpleHero from "../../components/simple-hero/simple-hero";
import { contactPhoneNumbers } from "../../utils/constants";

function TocaAbsolvire() {
  return (
    <div className="Home">
      <div id="top"></div>
      <SimpleHero
        title={
          <>
            Toca Absolvire <br></br> Inchiriaza sau Cumpara
          </>
        }
        image={require("./../../assets/banners/2.png")}
      />

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
        Tocă absolvire festivitate facultate personalizată în funcție de preferințele tale
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side">
            <div className="p1 mt-2">
              {`
            Te-ai gândit vreodată cât de important este aspectul tău la absolvirea facultății? Atunci când sărbătorești finalizarea cursurilor tale? Acest eveniment marcant al vieții tale.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Ai vrea să arăți elegant și să ieși în evidență în acea zi specială?
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Ei bine pe absolvirea.ro găsești acea tocă de absolvire festivitate pentru facultate personalizată în funcție de preferințele tale. Alege acum dintr-o gamă variată de modele și fă-ți apariția dar și această zi una cu adevărat memorabilă!
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Îți oferim o selecție diversă de toci, astfel încât să poți găsi cu ușurință tocă potrivită personalității tale. Indiferent că îți dorești un model clasic sau unul modern, aici vei găsi exact ceea ce cauți.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Toate tocile noastre sunt produse de către noi din materiale de înaltă calitate, pentru a se potrivi cu cele mai înalte standarde și ți le vom aduce curate și călcate pentru ca tu să ai parte de acea festivitate de vis, fără nici o grijă.
            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/toci/1.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
        Alege-ți un model tocă absolvire ieftină la preț de producător
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
            Dacă ești în căutare unui model tocă absolvire ieftină la preț bun, ai ajuns în locul potrivit pentru că aici găsești exact ceea ce cauți. După ce ți-ai ales modelul preferat, dacă îți dorești să îți personalizezi toca, suntem aici pentru a te ajuta să îți transpui ideile în realitate. Poți alege modelul dorit, dimensiunea, culoarea și textul pe care îl dorești să îl afișezi pe tocă.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Astfel, vei fi sigur că ai un produs unic și special, care te va ajuta să te simți bine în pielea ta la absolvire și care să facă din această zi una cu adevărat specială.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pentru a completa aspectul tău la absolvirea facultății, îți oferim și alte produse personalizate, cum ar fi robe, eșarfe, cocarde și diplome. Aceste produse pot fi de asemenea personalizate cu numele tău, numele facultății și data absolvirii. Astfel, vei avea un set complet de produse personalizate, care îți vor aduce aminte mereu de această zi specială.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Comandă acum toca ta personalizată de pe site-ul nostru și bucură-te de un aspect elegant și sofisticat la absolvirea facultății. Și nu uita să alegi și o robă.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Suntem aici pentru a te ajuta să creezi produse personalizate care să se potrivească cu stilul și personalitatea ta, astfel încât să te simți cu adevărat special în acea zi memorabilă.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Comandă-ți acum toca preferată și bucură-te de un aspect elegant și sofisticat la absolvirea facultății!
            `}
            </div>

          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/toci/2.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>
      <div className="p-relative container mt-3 mt-lg-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button content={<>{contactPhoneNumbers[0]}</>} width="400px" type="outline" classes="" link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      <div className="p-relative mt-3 mt-lg-5">
        <Footer />
      </div>
    </div>
  );
}

export default TocaAbsolvire;
