import { useContext, useEffect } from "react";
import "./footer.scss";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../modals/alert-modal/alert-modal";
import facebookIcon from "./../../assets/icons/facebook.svg";
import instagramIcon from "./../../assets/icons/instagram.svg";
import linkedinIcon from "./../../assets/icons/linkedin.svg";
import tikTokIcon from "./../../assets/icons/tiktok.svg";
import youtubeIcon from "./../../assets/icons/youtube.svg";
import eduardDigitalLogo from "./../../assets/logos/eduard-digital.png";
import { contactPhoneNumbers } from "../../utils/constants";

function Footer() {
  const { handleModal } = useContext(ModalContext);

  const openContactModal = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={() => {}}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  return (
    <div className="footer-container pb-5 pt-5">
      <div className="container p-relative">
        <div className="pl-2 pr-2 pl-md-0 pr-md-0">
          <div className="d-flex gap-3 flex-wrap mt-4 justify-content-around text-center text-md-left">
            <div className="mt-2 d-flex flex-direction-column justify-content-center">
              <a className="no-decoration white-link" href="/">
                <p>Acasă</p>
              </a>
              <a className="no-decoration white-link" href="/#pachete">
                <p>Pachete</p>
              </a>
              <a className="no-decoration white-link" href="/pachete-absolvire-liceu-facultate-generala">
                <p>Pachete absolvire</p>
              </a>
              <a className="no-decoration white-link" href="/toca-absolvire">
                <p>Toci</p>
              </a>
              <div onClick={openContactModal} className="no-decoration white-link" href="">
                <p>Contact</p>
              </div>
            </div>

            <div className="mt-2 d-flex flex-direction-column justify-content-center">
              <a className="no-decoration white-link" href="/esarfe-absolvire">
                <p>Esarfe</p>
              </a>
              <a className="no-decoration white-link" href="/cocarde-absolvire">
                <p>Cocarde</p>
              </a>
              <a className="no-decoration white-link" href="/diplome-onorifice">
                <p>Diplome onorifice</p>
              </a>
              <a className="no-decoration white-link" href="/diplome-de-absolvire">
                <p>Diplome de absolvire</p>
              </a>
              <div onClick={openContactModal} className="no-decoration white-link" href="">
                <p>Contact</p>
              </div>
            </div>

            <div className="mt-2 d-flex flex-direction-column justify-content-center">
              <a className="no-decoration white-link" href="/termeni-si-conditii">
                <p>Termeni și condiții</p>
              </a>
              <a className="no-decoration white-link" href="/politica-de-confidentialitate">
                <p>Politica de confidențialitate</p>
              </a>
              <a className="no-decoration white-link" href="/politica-de-cookies">
                <p>Politica de cookies</p>
              </a>
              <a className="no-decoration white-link" href="/acord-newsletter">
                <p>Acord newsletter</p>
              </a>
            </div>

            <div className="d-flex flex-direction-column justify-content-center">
              {/*               
              <div className="d-flex gap-2 justify-content-between">
                <a className="no-decoration white-link" href=" https://www.facebook.com/profile.php?id=61551091742308" target="_blank" rel="noopener noreferrer">
                  <img src={facebookIcon} alt="Facebook"></img>
                </a>
                <a className="no-decoration white-link" href="https://www.youtube.com/@absolvirea.ro./featured" target="_blank" rel="noopener noreferrer">
                  <img src={youtubeIcon} alt="YouTube"></img>
                </a>
                <a className="no-decoration white-link" href="https://www.instagram.com/absolvirea.ro" target="_blank" rel="noopener noreferrer">
                  <img src={instagramIcon} alt="Instagram"></img>
                </a>
              </div> 
              */}

              <div className="d-flex align-items-center justify-content-center mt-2 flex-wrap">
                Website built by
                <a
                  className="no-decoration white-link"
                  href="https://eduard.digital"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={eduardDigitalLogo} alt="Eduard.digital"></img>
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex flex-direction-column justify-content-center align-items-center mt-3 text-center text-md-left">
            Copyright @ {new Date().getFullYear()} Absolvirea. All Rights Reserved
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 text-center text-md-left gap-1">
              <a href={`tel:${contactPhoneNumbers[0]}`} alt="phone-number">
              {`Contact: ${contactPhoneNumbers[0]}`}
              </a>
            <a href={`tel:${contactPhoneNumbers[1]}`} alt="phone-number">
            {` sau ${contactPhoneNumbers[1]}`}
            </a>

          </div>
        </div>

        <div className="footer-logo">
          <img width="100%" src={require("./../../assets/logo-vertical.png")} alt="Absolvirea"></img>
        </div>
      </div>
    </div>
  );
}

export default Footer;
