import NavMenuBar from "./../../components/nav-menu-bar/nav-menu-bar";
import { Outlet, useLocation } from "react-router-dom";
import "./layout.scss";
import ModalContainer from "../../utils/modal/modalContainer";
import { ModalProvider } from "../../utils/modal/modalContext";
import { getFirestore } from "firebase/firestore";
import { AnalyticsProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import ButtonHero from "../../components/button-hero/button-hero";
import { getAnalytics, setCurrentScreen, logEvent } from "firebase/analytics";
import { useEffect } from "react";
// import ReactPixel from 'react-facebook-pixel';
// import TagManager from 'react-gtm-module'
// import ReactGA from 'react-ga4';
import { isAppleDevice } from "../../utils/utils";
import Button from "../../components/button/button";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

// ReactGA.initialize(process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS_4_ID);
// console.log("ReactGA.init")


function Layout() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const analyticsInstance = getAnalytics(useFirebaseApp());
  const location = useLocation();

  useEffect(()=>{   
    // Firebase Analytics tracking
    // 
    setCurrentScreen(analyticsInstance, location?.pathname);
    logEvent(analyticsInstance, 'screen_view');

    // Google Analytics 4 tracking
    // 
    // ReactGA.send({ hitType: "pageview", page: location.pathname });

    // Meta tracking
    // 
    // if (window?.fbq) {
    //   window.fbq('track', 'PageView');
    // }

    // Google ADS AdWords tracking
    // 
    // if (window.gtag) {
    //   window.gtag('config', process.env.REACT_APP_PUBLIC_GOOGLE_ADS_TAG);
    //   window.gtag('event', 'page_view', {'send_to': `${process.env.REACT_APP_PUBLIC_GOOGLE_ADS_TAG}/${location?.pathname}`});
    // }
  },[analyticsInstance, location]);


  return (
    <div className={`main-layout ${isAppleDevice()?"apple-device":"non-apple-device"}`}>
      <FirestoreProvider sdk={firestoreInstance}>
        <AnalyticsProvider sdk={analyticsInstance}>
          <ModalProvider>
            <NavMenuBar />
            <Outlet />

            {/* <CookieConsent
              enableDeclineButton
              flipButtons
              location="bottom"
              buttonText={
                <div className="cookie-confirm-button">
                  <Button color="white" scale={"0.6"} content="Accept cookie-urile"></Button>
                </div>
              }
              declineButtonText={
                <div className="mb-2 cookie-decline-button">
                Nu accept cookie-urile
              </div>
              }
              cookieName="myAwesomeCookieName2"
              style={{ background: "rgb(255 153 0 / 80%)", backdropFilter: "blur(8px)", boxShadow: "0px 20px 50px 15px #1b1b1b"}}
              buttonStyle={{ all: "unset", color: '#1b1b1b' }}
              declineButtonStyle={{ all: "unset", cursor: "pointer" }}
              expires={150}
              // onAccept={() => ReactPixel.grantConsent()}
              // onDecline={() => ReactPixel.revokeConsent()}
              >
              Acest site folosește cookie-uri pentru a îmbunătăți experiența.{" "}
            </CookieConsent> */}

            <ModalContainer />
          </ModalProvider>
        </AnalyticsProvider>
      </FirestoreProvider>
      <ToastContainer />
    </div>
  );
}

export default Layout;
