import { useState } from "react";
import "./review-item.scss";

function ReviewItem({reviewer, key}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="review-item-container" key={key}>
      <img className="review-photo" src={reviewer.photo} alt={reviewer.name}/>
      <div className="h3 color-primary">{`${reviewer.name}, ${reviewer.year}`}</div>
      <div className={`p1 expandable ${isExpanded?"expanded":"collapsed"}`} onClick={()=>setIsExpanded(x=>!x)}>{reviewer.review}</div>
    </div>
  );
}

export default ReviewItem;
