import { useContext, useEffect, useState } from "react";
import AnimationRevealer from "../../components/animation-revealer/animation-revealer";
import Hero from "../../components/hero/hero";
import "./home.scss";
import "animate.css";
import Footer from "../../components/footer/footer";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";
import { ModalContext } from "../../utils/modal/modalContext";
import { useLocation } from "react-router-dom";
import PackageFrame1 from "../../components/package-frames/package-frame-1/packageFrame1";
import ResponsivePackageFrame from "../../components/package-frames/responsive-package-frame/responsivePackageFrame";
import PackageFrame2 from "../../components/package-frames/package-frame-2/packageFrame2";
import PackageFrame3 from "../../components/package-frames/package-frame-3/packageFrame3";
import {
  allExtraPoints,
  standardOffer,
  premiumOffer,
  allInclusiveOffer,
  flexibleOffer,
  reviews,
  contactPhoneNumbers,
} from "../../utils/constants";
import { delay, isMobileDevice } from "../../utils/utils";
import Button from "../../components/button/button";
import Gallery from "../../components/gallery/gallery";
import ReviewItem from "../../components/review-item/review-item";

const offers = [
  {
    ...standardOffer,
    extraPoints: standardOffer.extraPoints.map((ep) => {
      if (standardOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
        ep.isSelectable = false;
        ep.isSelected = true;
      }
      return ep;
    }),
  },
  {
    ...premiumOffer,
    extraPoints: premiumOffer.extraPoints.map((ep) => {
      if (premiumOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
        ep.isSelectable = false;
        ep.isSelected = true;
      }
      return ep;
    }),
  },
  {
    ...allInclusiveOffer,
    extraPoints: allInclusiveOffer.extraPoints.map((ep) => {
      if (allInclusiveOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
        ep.isSelectable = false;
        ep.isSelected = true;
      }
      return ep;
    }),
  },
  {
    ...flexibleOffer,
    extraPoints: flexibleOffer.extraPoints.map((ep) => {
      if (flexibleOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
        ep.isSelectable = false;
        ep.isSelected = true;
      }
      return ep;
    }),
  },
];

function Home() {
  const { hash } = useLocation();
  const { handleModal } = useContext(ModalContext);

  const stakeholders = [
    {
      imageUrl: require("./../../assets/stakeholders-1.webp"),
      title: "Școala generala",
      description: (
        <>
          {`Avem robe atât pentru clasa a `}
          <span className="text-nowrap">IV-a</span>
          {`, cât și pentru a `}
          <span className="text-nowrap">VIII-a</span>
          {`.`}
        </>
      ),
    },
    {
      imageUrl: require("./../../assets/stakeholders-2.webp"),

      title: "Liceu",
      description: "Dacă sunteți la liceu, vă stăm la dispoziție cu robe, toci și eșarfe.",
    },
    {
      imageUrl: require("./../../assets/stakeholders-3.webp"),

      title: "Facultate",
      description: "Dacă sunteți la facultate, noi reprezentăm soluția perfectă.",
    },
    {
      imageUrl: require("./../../assets/stakeholders-4.webp"),

      title: "Colaboratori",
      description: "Suntem unici producători. Doriți o colaborare cu noi?",
    },
  ];

  useEffect(() => {
    console.log("hash changed", hash);
    if (hash === "#pachete") {
      document.getElementById("pachete")?.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const openOrderFinalizationModal = (orderData) => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.FinishOrder}
          className="modal-wide"
          title={`Finalizează comanda - ${orderData.title}`}
          successLabel="Trimite comanda"
          cancelLabel="Anulează"
          extraData={orderData}
          onDismiss={() => {}}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  const orderPackage = async (orderData, withDelay = true) => {
    console.log("orderPackage", orderData);
    if (!orderData?.extraOptionsSubtotal) {
      orderData.extraOptionsSubtotal = 0;
      orderData.grandTotal = orderData.price;
    }
    if (withDelay) {
      await delay(1000);
    }
    await openOrderFinalizationModal(orderData);
  };

  return (
    <div className="Home">
      <div id="top"></div>
      <Hero />

      <div className="p-relative container mt-3">
        <div className="h1 section-divider-title">Cui ne adresăm?</div>
        <div className="d-flex flex-wrap mt-3 gap-3 justify-content-center align-items-center pl-1 pr-1 pl-lg-2 pr-lg-2 pl-xl-4 pr-xl-4">
          {stakeholders.map((stakeholder, index) => {
            return (
              <div className="text-center stakeholder-item" key={"stakeholder-item-" + index}>
                <img src={stakeholder.imageUrl} alt={stakeholder.title} className="no-selection" />
                <div className="h2">{stakeholder.title}</div>
                <div className="p1">{stakeholder.description}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="p-relative container mt-5">
        <div className="h1 section-divider-title">
          Închirieri robe de absolvire în culori diverse la preț bun pe absolvirea.ro
        </div>
        <div className="half-half mt-2 gap-3">
          <div className="">
            <div className="p1 mt-2">
              {`
            Finalizarea studiilor este un moment unic în viața.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Fie că ne referim la școala generală, liceu sau facultate, acest moment trebuie păstrat în amintire ca un moment al succesului, al reușitei și ar trebui să fie unul perfect. Indiferent de nivelul de absolvire, fiecare robă de pe absolvirea.ro este special concepută pentru a se potrivi perfect absolventului și a oferi confortul necesar în timpul ceremoniei dar și pentru a fi îmbrăcat într-o haină de sărbătoare.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Indiferent de studiile pe care le-ai finalizat îți oferim o robă absolvire de vânzare sau servicii de închiriere robe absolvire în modele și culori diverse, în funcție de preferințele tale la prețuri bune cât și opțiunea de a le personaliza.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Robele noastre sunt confectionate din materiale de înaltă calitate, cu finisaje deosebite, pentru a putea fi purtate cu mândrie în cele mai importante momente ale vieții. Fie ca ești absolvent al scolii generale, al liceului sau al facultății, avem modele variate pentru a se potrivi stilului tău.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Tu alegi ce culori ale esarfelor îți dorești sau dacă vrei să ai parte și de alte accesori alături de roba ta, precum medalie, plachetă personalizată, trofeu, invitații sau cocarde.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Decide să faci din ziua absolvirii tale una de neuitat purtând robe de absolvire de vânzare sau de închiriat la prețuri de producător de calitate.
            `}
            </div>
          </div>
          <img
            src={require("./../../assets/image-27.webp")}
            alt={"Inchirieri robe"}
            width="100%"
            className="no-selection"
            style={{ height: "auto" }}
          />
        </div>
      </div>

      <div id="pachete" className="p-relative container mt-3">
        <div className="h1 section-divider-title">Oferta noastră</div>
        <div className="d-flex flex-wrap mt-3 gap-3 justify-content-around align-items-start">
          {/* {offers.map((offer, index) => {
            return (
              <div className="" key={"package-offer-item-" + index}>
                  <PackageFrame title={offer.title} content={offer.content}/>
              </div>
            );
          })} */}
          <PackageFrame1 offer={offers[0]} onSuccess={orderPackage} />
          <PackageFrame2 offer={offers[1]} onSuccess={orderPackage} />
          <img
            className="frame-replacement"
            alt={"Ornament absolvire"}
            src={require("./../../assets/frame-borders/graduation-ornament.webp")}
          />

          <PackageFrame3 offer={offers[2]} onSuccess={orderPackage} />
          <ResponsivePackageFrame offer={offers[3]} onSuccess={orderPackage} />
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <div className="h1 section-divider-title">Beneficii</div>

        <div className="half-half mt-2 align-items-center gap-3">
          <img
            src={require("./../../assets/decoration-1.png")}
            alt={"Inchirieri robe"}
            width="100%"
            className="no-selection lg-under"
            style={{ height: "auto" }}
          />

          <div className="text-left text-lg-center">
            <div className="p1 mt-2">
              {`
            Pentru ca acest moment să fie unul demn de ținut minte și  să ai parte de o celebrare ca la carte, îți oferim mai multe pachete din care poți alege robe absolvire de cumparat sau de închiriat.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pentru școala generală poți opta pentru robe pentru clasa a `}
              <span className="text-nowrap">IV-a</span>
              {`, cât și pentru a `}
              <span className="text-nowrap">VIII-a</span>
              {`.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pentru finalizarea liceului poți alege robe, toci, eșarfe ce pot fi personalizate cu text cât și în nuanța dorită de tine.
            `}
            </div>
          </div>

          <img
            src={require("./../../assets/decoration-1-mirrored.png")}
            alt={"Inchirieri robe"}
            width="100%"
            className="no-selection d-none d-lg-block"
            style={{ height: "auto" }}
          />
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <div className="h1 section-divider-title">Pregătirea comenzii</div>

        <div className="p1 mt-2">
          {`
            Este foarte ușor să înregistrezi o comandă la noi.
            `}
        </div>
        <div className="p1 mt-2">
          {`
            Un specialist este pregătit permanent să onoreze comanda ta și îți stă alături cu sfaturi utile pentru ca totul să decurgă perfect.
            `}
        </div>

        <div className="mt-2">
          <div className="half-half gap-2 align-items-start">
            <div className="mt-2">
              <div className="h3">
                {`
            Pasul 1 - Înregistrarea comenzii
            `}
              </div>
              <div className="p1 mt-2">
                {`
            Ne suni, alegem împreună pachetul dorit, iar apoi îți înregistrăm comanda. În funcție de pachet, îți vom trimite pe mail modele de diplome, culori pentru eșarfe și tot ce ai nevoie pentru o festivitate de vis.
            `}
              </div>
            </div>

            <div className="mt-2">
              <div className="h3">
                {`
            Pasul 2 - Pregătirea comenzii
            `}
              </div>
              <div className="p1 mt-2">
                {`
            După ce te-ai hotărât asupra modelelor de diplome, culorilor pentru eșarfe și a numărului de pachete, noi ne ocupăm de pregătirea comenzii, ca să îți ajungă la timp.
            Așa că stai fără griji și lasă totul în grija noastră.
            `}
              </div>
            </div>
          </div>
          <div className="half-half gap-2">
            <div className="mt-2">
              <div className="h3">
                {`
            Pasul 3 - Livrarea comenzii
            `}
              </div>
              <div className="p1 mt-2">
                {`
            Totul este gata de livrare, așa că împreună cu tine stabilim cum dorești să primești pachetele de absolvire. Putem livra atât prin curier oriunde în țară, astfel încât să ajungă la tine cu câteva zile înaintea festivității, sau o echipă poate să ajungă la tine chiar în ziua absolvirii.
            `}
              </div>
            </div>

            <div className="mt-2">
              <div className="h3">
                {`
            Pasul 4 - Zâmbim
            `}
              </div>
              <div className="p1 mt-2">
                {`
            Acum, că totul a ieșit perfect, zâmbește și bucură-te de aceste momente unice. Absolvirea reprezintă unul dintre momentele unice din viața fiecăruia.
            `}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button
          content={<>{contactPhoneNumbers[0]}</>}
          width="400px"
          type="outline"
          classes=""
          link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      {/* <div className="mt-5 image-divider animate__animated animate__zoomInDown reveal-on-scroll"> */}
      <div className="mt-3 mt-lg-5 image-divider">
        <div className="container">
          <div className="image-divider-content">
            <div className="h1 font-semi-bold">
              Suntem o echipă tânără, dinamică și ne adaptăm cerințelor fiecărui client.
            </div>
            <div className="mt-3 h3 font-semi-bold">
              Oferim servicii personalizate în materie de închirieri robe absolvire. Fie că ești în pragul absolvirii,
              fie că ești interesat să achiziționezi robe, toci și eșarfe de la noi vă suntem alături.
            </div>
            <div className="mt-3 h3 font-semi-bold">
              Ne caracterizează seriozitatea și calitatea. Abia așteptăm să lucrăm cu tine.
            </div>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-5">
        <div className="h1 section-divider-title">
          Robe absolvire de cumpărat sau de închiriat pentru un moment memorabil
        </div>
        <div className="half-half mt-2 gap-3">
          <div className="">
            <div className="p1 mt-2">
              {`
            Pentru ca acest moment să fie unul demn de ținut minte și  să ai parte de o celebrare ca la carte, îți oferim mai multe pachete din care poți alege robe absolvire de cumparat sau de închiriat.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pentru școala generală poți opta pentru robe pentru clasa a `}
              <span className="text-nowrap">IV-a</span>
              {` cât și pentru clasa a `}
              <span className="text-nowrap">VIII-a</span>
              {`.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pentru finalizarea liceului poți alege robe, toci, eșarfe ce pot fi personalizate cu text cât și în nuanța dorită de tine.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Absolvirea facultății este de asemenea un moment special și merită o atenție deosebită. Astfel că îți oferim robe dar și alte accesorii din care poți alege exact ceea ce ai nevoie pentru a face din această zi un moment memorabil.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Tu decizi care este pachetul perfect pentru tine și dacă vrei să păstrezi roba pentru tine sau doar să o închiriezi. 
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Indiferent de alegerea ta îți oferim robe curate și călcate dar și eșarfe în culoarea preferată, modele pentru diplome și invitații, cocarde și tot ce ai nevoie pentru a face din festivitatea ta una de vis alături de profesori.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Fiind producători îți oferim nu doar calitate și prețuri foarte bune ci și asistență pentru a-ți transforma absolvirea într-un moment perfect.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Alege să îți săbătorești absolvirea într-un mod unic, îmbrăcându-te în haine perfect pentru această zi specială din viața ta.
            `}
            </div>
          </div>
          <img
            src={require("./../../assets/decoration-3.png")}
            alt={"Inchirieri robe"}
            width="100%"
            className="no-selection"
            style={{ height: "auto" }}
          />
        </div>
      </div>

      <div className="p-relative container mt-5">
        <div className="h1 section-divider-title mb-2">Fotografii</div>
        <Gallery />
      </div>

      <div className="p-relative container mt-5">
        <div className="h1 section-divider-title">Review-uri</div>

        <div className="d-flex mt-2 justify-content-around flex-wrap gap-3 split-on-lg">
          {reviews.map((review, index) => {
            return <ReviewItem key={`review-${index}`} reviewer={review} />;
          })}
        </div>
      </div>

      <div className="p-relative container mt-5">
        <div className="media-container mt-4">
          <iframe
            width="100%"
            height="100%"
            src={`${`https://www.youtube.com/embed/bqtUJWKV1jw?si=Gy71tGu5Djt4u9Tk`}?autoplay=0&rel=0&${
              isMobileDevice() ? "mute=0" : "mute=1"
            }&enablejsapi=1;controls=0`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
        </div>
      </div>

      <div className="p-relative container mt-3">
        <div className="half-half mt-2 gap-3">
          <img
            src={require("./../../assets/decoration-4.png")}
            alt={"Inchirieri robe"}
            width="100%"
            className="no-selection lg-under"
            style={{ height: "auto" }}
          />

          <div className="text-center">
            <div className="p2 mt-2">
              {`
            Suntem producători de TOCI, EȘARFE și ROBE de Absolvire.
            `}
            </div>
            <div className="p2 mt-2">
              {`
            Robele, tocile și eșarfele sunt din minimat de cea mai bună calitate.
            `}
            </div>
            <div className="p2 mt-2">
              {`
            Vă stăm la dispoziție cu o paletă largă de culori pentru eșafe, o gamă variată de modele pentru diplome și invitații, cocarde și tot ceea ce aveți nevoie pentru ca festivitatea dumneavoastră să fie una de vis.
            `}
            </div>
            <div className="p2 mt-2">
              {`
            Oferim servicii de închirieri robe absolvire în: Bucuresti, Constanta, Pitesti, Craiova, Slatina, Sibiu, Oradea, Timisoara, Iasi, Ramnicu Valcea, Satu Mare, Vaslui, Baia Mare, Ploiesti, Zimnicea, Slatina, Cluj, Buzau, Cluj Napoca, Arad, Miercurea Ciuc, Oradea, inchirieri robe Brasov, Tulcea, Petrosani, Braila, Galati, Zalau, Resita, Dobreta-Turnu Severin, Deva, Alexandria, Giurgiu, Calarasi, Slobozia, Focsani, Bacau, Piatra Neamt, Suceava, Calarasi, Targoviste, Sfantu Gheorghe, Targu Mures, Alba Iulia, Lugoj, Targu Mures, Iasi, Medias, Sighisoara, Roman, Focsani, Oltenita, Piatra Neamt, Bistrita, Targu Jiu, Isaccea, Tg. Jiu .
            `}
            </div>
          </div>

          <img
            src={require("./../../assets/decoration-4-mirror.png")}
            alt={"Inchirieri robe"}
            width="100%"
            className="no-selection d-none d-lg-block"
            style={{ height: "auto" }}
          />
        </div>
      </div>

      <div className="p-relative container mt-3 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button
          content={<>{contactPhoneNumbers[0]}</>}
          width="400px"
          type="outline"
          classes=""
          link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      <div className="mt-3 mt-lg-5">
        <Footer />
      </div>

      <AnimationRevealer />
    </div>
  );
}

export default Home;
