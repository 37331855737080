import Button from "../../button/button";
import OfferItemPoint from "../../offer-item-point/offer-item-point";
import "./responsivePackageFrame.scss";
import { useContext } from "react";
import AlertModal, { alertModalTypes } from "../../modals/alert-modal/alert-modal";
import { ModalContext } from "../../../utils/modal/modalContext";

function ResponsivePackageFrame({ classes = "", offer = {}, onClick = () => {}, onSuccess = () => {}}) {
  const { handleModal } = useContext(ModalContext);

  const openExtraOptionsModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.ExtraOptions}
          className="modal-wider"
          title="Alege extra opțiuni"
          successLabel="Comandă"
          cancelLabel="Anulează"
          extraData={{ offer: offer }}
          onDismiss={() => {}}
          onSuccess={onSuccess}></AlertModal>
      );
    });
  };

  return (
    <div className="responsive-frame-container">
      <div className={`${classes} package-frame responsive-package-frame `} onClick={onClick}>
        <img
          className="ornaments"
          height="100%"
          alt={offer?.title}
          src={require("./../../../assets/frame-borders/responsive/ornaments.webp")}
        />

        <div className="top-frame">
          <div className="top-left" />

          <div className="top-middle-1" />
          <div className="top-middle-2" />

          <div className="top-right" />
        </div>

        <div className="middle-frame">
          <div className="middle-left" />

          <div className="middle-middle">
            <div className="middle-title package-title">{offer?.title}</div>
            <div className="middle-content">
              <div className="bullet-points-wrapper">
                <div className="bullet-points-column">
                  {offer?.extraPoints.slice(0, offer?.extraPoints?.length / 2 + 2).map((point, index) => {
                    return (
                      <div className="bullet-point-left" key={"package-offer-" + offer?.title + "-point1-" + index}>
                        <OfferItemPoint content={point.content} price={point.price} currency={point.currency} isSelectable={point.isSelectable}  />
                      </div>
                    );
                  })}
                </div>
                <div className="bullet-points-column">
                  {offer?.extraPoints
                    .slice(offer?.extraPoints?.length / 2 + 2, offer?.extraPoints?.length)
                    .map((point, index) => {
                      return (
                        <div className="bullet-point-left" key={"package-offer-" + offer?.title + "-point2-" + index}>
                          <OfferItemPoint content={point.content} price={point.price} currency={point.currency} isSelectable={point.isSelectable}  />
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <div className="package-actions mt-2">
                <Button
                  scale="0.8"
                  content={
                    <div className="d-flex flex-wrap justify-content-center gap-1">
                      <div>{`Comandă`}</div>
                    </div>
                  }
                />
              </div> */}
              <div className="package-actions mt-2">
                <Button
                  scale="0.8"
                  onClick={openExtraOptionsModal}
                  content={
                    <div className="d-flex flex-wrap justify-content-center gap-1">
                      <div>{`Alege opțiunile`}</div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <div className="middle-right" />
        </div>

        <div className="bottom-frame">
          <div className="bottom-left" />

          <div className="bottom-middle-1" />
          <div className="bottom-middle-2" />
          <div className="bottom-right" />
        </div>
      </div>
    </div>
  );
}

export default ResponsivePackageFrame;
