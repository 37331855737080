import "./gallery.scss";
import LightGallery from "lightgallery/react";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

function Gallery() {
  return (
    <div className="gallery-container">
      <LightGallery alignThumbnails="middle"
       speed={500} plugins={[lgThumbnail, lgZoom]}>
        <a href={require("./../../assets/gallery/1.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/1.webp")} />
        </a>        
        <a href={require("./../../assets/gallery/2.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/2.webp")} />
        </a>
        <a href={require("./../../assets/gallery/3.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/3.webp")} />
        </a>
        <a href={require("./../../assets/gallery/4.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/4.webp")} />
        </a>
        <a href={require("./../../assets/gallery/6.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/6.webp")} />
        </a>
        <a href={require("./../../assets/gallery/7.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/7.webp")} />
        </a>
        <a href={require("./../../assets/gallery/9.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/9.webp")} />
        </a>
        <a href={require("./../../assets/gallery/11.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/11.webp")} />
        </a>
        <a href={require("./../../assets/gallery/12.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/12.webp")} />
        </a>
        <a href={require("./../../assets/gallery/13.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/13.webp")} />
        </a>
        <a href={require("./../../assets/gallery/14.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/14.webp")} />
        </a>
        <a href={require("./../../assets/gallery/20.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/20.webp")} />
        </a>
        <a href={require("./../../assets/gallery/21.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/21.webp")} />
        </a>
        <a href={require("./../../assets/gallery/22.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/22.webp")} />
        </a>
        <a href={require("./../../assets/gallery/23.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/23.webp")} />
        </a>
        <a href={require("./../../assets/gallery/24.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/24.webp")} />
        </a>
        <a href={require("./../../assets/gallery/25.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/25.webp")} />
        </a>
        <a href={require("./../../assets/gallery/26.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/26.webp")} />
        </a>
        <a href={require("./../../assets/gallery/8.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/8.webp")} />
        </a>
        <a href={require("./../../assets/gallery/10.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/10.webp")} />
        </a>
        <a href={require("./../../assets/gallery/15.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/15.webp")} />
        </a>
        <a href={require("./../../assets/gallery/16.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/16.webp")} />
        </a>
        <a href={require("./../../assets/gallery/17.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/17.webp")} />
        </a>
        <a href={require("./../../assets/gallery/18.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/18.webp")} />
        </a>
        <a href={require("./../../assets/gallery/19.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/19.webp")} />
        </a>
        <a href={require("./../../assets/gallery/5.webp")}>
          <img className="thumbnail" alt="" src={require("./../../assets/gallery/5.webp")} />
        </a>

      </LightGallery>
    </div>
  );
}

export default Gallery;
