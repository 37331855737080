import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import SimpleHero from "../../components/simple-hero/simple-hero";
import { contactPhoneNumbers } from "../../utils/constants";

function DiplomeOnorifice() {
  return (
    <div className="Home">
      <div id="top"></div>
      <SimpleHero
        title={
          <>
            Diplome Onorifice <br></br> Inchiriaza sau Cumpara
          </>
        }
        image={require("./../../assets/banners/5.png")}
      />

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">Diplome onorifice pentru profesori & elevi valoroși</h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
              Diplomele onorifice sunt o modalitate excelentă de a-ți arăta respectul și aprecierea față de profesori. Acei profesori care au investit timp și energie într-o educație de excepție. 

              `}
            </div>
            <div className="p1 mt-2">
              {`
           Cu o diplomă personalizată, poți spune „mulțumesc” într-un mod special și memorabil. Poți adăuga un mesaj de prețuire și respect pentru acel profesor care a ghidat elevul sau studentul pe drumul educației valoroase. 

           `}
            </div>
            <div className="p1 mt-2">
              {`
              Din fericire, acum este mai ușor ca niciodată să comanzi diplome onorifice pentru profesorii de excepție. Acei profesori care educă generații întregi de valori. 

            `}
            </div>
            <div className="p1 mt-2">
              {`
                      Există o mulțime de opțiuni disponibile de diplome, dar dacă dorești să te asiguri că ai cele mai bune diplome la cele mai bune prețuri, poți să alegi să comanzi online din oferta noastră disponibilă și pusă la dispoziția ta. 

            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/diplome-onorifice/1.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
          Oferă profesorilor ocazia perfectă să-și amintească cândva de cea mai frumoasă festivitate de absolvire
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
                       Oferim diplome onorifice personalizate de cea mai bună calitate, pe care le putem livra rapid în toată țara. Așadar, indiferent că te afli în București sau într-o altă localitate din România, nu trebuie să-ți faci griji. 

            `}
            </div>
            <div className="p1 mt-2">
              {`
                      Comandă modelul preferat la cel mai bun preț, iar noi te asigurăm de livrarea comenzii tale online direct la ușa ta.

            `}
            </div>

            <div className="p1 mt-2 font-bold">
              {`
            Impresionează  profesorii dedicați elevilor sau studenților săi alegând să comanzi o diplomă onorifică pe care să o personalizezi cu un text emoționant!

            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/diplome-onorifice/2.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>
      <div className="p-relative container mt-3 mt-lg-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button content={<>{contactPhoneNumbers[0]}</>} width="400px" type="outline" classes="" link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      <div className="p-relative mt-3 mt-lg-5">
        <Footer />
      </div>
    </div>
  );
}

export default DiplomeOnorifice;
