import "./button.scss";

function Button({
  link,
  target,
  rel,
  content,
  type = "full",
  color = "primary",
  className = "",
  scale = 1,
  size = 1,
  outlineOnHover = false,
  width = "500px",
  onClick = ()=>{},
}) {
  // Color can be primary | secondary | white | accent
  // Type can be full | outline
  return (
    <div style={{width: `min(100%, ${width})`}} className={`${className}`}>
      <a href={link} target={target} rel={rel} onClick={onClick}>
          <div
            className={`button-container ${
              outlineOnHover ? "outline-on-hover" : ""
            }`}
            style={{scale: scale}}
            tabIndex={0}>
            <div className={`button text-center type-${type} color-${color}`}>{content}</div>
          </div>
      </a>
    </div>
  );
}

export default Button;
