import Button from "../button/button";
import ButtonHero from "../button-hero/button-hero";
import "./simple-hero.scss";
import { contactPhoneNumbers } from "../../utils/constants";
import { useContext } from "react";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../modals/alert-modal/alert-modal";
import { goToPhoneOrContactDependingOnDevice } from "../../utils/utils";

function SimpleHero({ title, image }) {
  const { handleModal } = useContext(ModalContext);

  const openContactModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={() => {}}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  return (
    <div className="simple-hero-container">
      <div className="simple-hero-background">
        <img alt="motivational-event" className="w-100" src={image}></img>
      </div>
      <header className="p-relative h-100 container text-white d-flex justify-content-evenly align-items-center flex-direction-column">
        <div className="d-flex justify-content-evenly align-items-center flex-direction-column">
          <h1 className="hero-heading text-center mt-2 mt-md-6">
            <div className="mb-0">{title}</div>
          </h1>
        </div>
        <div className="w-100 pl-1 gap-4 p-relative d-flex flex-wrap justify-content-center align-items-center">
          <ButtonHero
            content="Comandă acum"
            is3d={true}
            isPulsing={true}
            width="400px"
            color={"primary-white"}
            link="/#pachete"></ButtonHero>

          <Button
            content={<>{contactPhoneNumbers[0]}</>}
            width="400px"
            type="full"
            color="white"
            classes="mt-2"
            onClick={() => goToPhoneOrContactDependingOnDevice(openContactModal)}></Button>
        </div>
      </header>
    </div>
  );
}

export default SimpleHero;
