import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import SimpleHero from "../../components/simple-hero/simple-hero";
import { contactPhoneNumbers } from "../../utils/constants";

function EsarfeAbsolvire() {
  return (
    <div className="Home">
      <div id="top"></div>
      <SimpleHero
        title={
          <>
            Esarfe Absolvire <br></br> Inchiriaza sau Cumpara
          </>
        }
        image={require("./../../assets/banners/3.png")}
      />

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
          Alege eșarfe absolvire personalizate pentru ziua memorabilă a festivității tale
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
            Imaginază-ți acea zi perfectă în care îți sărbătorești finalizarea studiilor. În care ești un absolvent și meriți să porți elemente care să te facă mândru de toate reușitele tale.
            Acum ești pe punctul de a sărbători una dintre cele mai mari realizări ale vieții tale, absolvirea.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Este un moment în care trebuie să te simți pe deplin realizat, în timp ce privești spre viitorul strălucit care te așteaptă. Cu toate acestea, acest moment nu ar trebui să fie uitat sau lăsat în umbră ci din contră, acum este timpul să ieși în față, într-un mod inedit, atunci când pășești pe scena, pe noul tău drum în viață.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            În această zi, este important să ai o amintire a acestui eveniment special. O eșarfă de absolvire personalizată este alegerea perfectă pentru a-ți aminti de acest moment unic din viața ta. Alegând să porți eșarfe de absolvire alegi să porți eleganța, rafinamentul și de ce nu, chiar aceste amintiri memorabile, pentru o viață.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Cele mai multe eșarfe de absolvire sunt de obicei în culori standard și fără nicio personalizare. Dar de ce să te mulțumești cu ceva obișnuit când poți avea o eșarfă personalizată unică?
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Fiecare eșarfă este disponibilă în diferite culori, astfel încât să se potrivească perfect cu tema facultății sau profilului tău. De asemenea, poți să personalizezi eșarfa cu anul absolvirii tale.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Astfel, nu doar că vei fi elegant în ziua absolvirii, dar vei avea și o amintire de durată pentru a-ți aminti de acest eveniment special în fiecare zi.
            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/esarfe/1.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
          Eșarfe de absolvire personalizate - calitate și eleganță într-un singur produs
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-1">
            <div className="p1 mt-2">
              {`
            Calitatea este un element crucial atunci când vine vorba de alegerea unei eșarfe de absolvire dacă vrei să te simți cu totul radiant și special în ziua absolvirii. Meriți să ai parte de un astfel de moment.

            `}
            </div>
            <div className="p1 mt-2">
              {`
            Fiecare eșarfă realizată de noi este făcută din materiale de înaltă calitate, astfel încât să arate bine pe tine în ziua absolvirii alături de roba și toca ta. 

            `}
            </div>
            <div className="p1 mt-2">
              {`
            Mai mult, te poți orienta și către alte accesorii precum cocarde, trofeuri, diplome și invitații. 

            `}
            </div>
            <div className="p1 mt-2">
              {`
            Comandă acum toca ta personalizată de pe site-ul nostru și bucură-te de un aspect elegant și sofisticat la absolvirea facultății. Și nu uita să alegi și o robă.
            `}
            </div>
            <div className="p1 mt-2">
              {`
              Dacă o închiriezi sau o achiziționezi pentru a o păstra peste ani este doar decizia ta. Noi putem să îți garantăm doar că vei avea parte de o zi specială, de un aspect curat, elegant și plin de rafinament la absolvirea ta. 
            `}
            </div>
            <div className="p1 mt-2">
              {`
              În plus, te asistăm pentru a lua cea mai bună decizie pentru ca întregul tău aspect să fie unul memorabil și își oferim prețuri de producător cât și alte servicii, în funcție de nevoile tale pentru a te bucura de această zi la intensitate maximă, fără nici o grijă.  
            `}
            </div>
            <div className="p1 mt-2">
              {`Alege să nu lași acest moment special să treacă neobservat. 
            `}
            </div>
            <div className="p1 mt-2">
              {`
Comandă-ți astăzi propria eșarfa de absolvire personalizată și vei avea o amintire pentru o viață întreagă. Această eșarfă este o alegere perfectă pentru a-ți aminti de un moment atât de special din viața ta. 
            `}
            </div>

          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/esarfe/2.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>
      <div className="p-relative container mt-3 mt-lg-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button content={<>{contactPhoneNumbers[0]}</>} width="400px" type="outline" classes="" link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      <div className="p-relative mt-3 mt-lg-5">
        <Footer />
      </div>
    </div>
  );
}

export default EsarfeAbsolvire;
