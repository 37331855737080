import "./styles/global.scss";
import Home from "./pages/home/home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/layout/layout";
import TermsConditions from "./pages/legal/terms-conditions";
import PoliticaDeConfidentialitate from "./pages/legal/politica-de-confidentialitate";
import PoliticaDeCookies from "./pages/legal/politica-de-cookies";
import AcordNewsletter from "./pages/legal/acord-newsletter";
import {FirebaseAppProvider} from "reactfire";
import PacheteAbsolvire from "./pages/pachete-absolvire/pachete-absolvire";
import TocaAbsolvire from "./pages/toca-absolvire/toca-absolvire";
import EsarfeAbsolvire from "./pages/esarfe-absolvire/esarfe-absolvire";
import CocardeAbsolvire from "./pages/cocarde-absolvire/cocarde-absolvire";
import DiplomeOnorifice from "./pages/diplome-onorifice/diplome-onorifice";
import DiplomeDeAbsolvire from "./pages/diplome-de-absolvire/diplome-de-absolvire";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PUBLIC_FIREBASE_MEASUREMENT_ID
};

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/esarfe-absolvire" element={<EsarfeAbsolvire />} />
              <Route path="/cocarde-absolvire" element={<CocardeAbsolvire />} />
              <Route path="/diplome-onorifice" element={<DiplomeOnorifice />} />
              <Route path="/diplome-de-absolvire" element={<DiplomeDeAbsolvire />} />
              <Route path="/toca-absolvire" element={<TocaAbsolvire />} />
              <Route path="/pachete-absolvire-liceu-facultate-generala" element={<PacheteAbsolvire />} />
              <Route path="/termeni-si-conditii" element={<TermsConditions />} />
              <Route path="/politica-de-confidentialitate" element={<PoliticaDeConfidentialitate />} />
              <Route path="/politica-de-cookies" element={<PoliticaDeCookies />} />
              <Route path="/acord-newsletter" element={<AcordNewsletter />} />
            </Route>
          </Routes>
        </BrowserRouter>
    </FirebaseAppProvider>
  );
}

export default App;
