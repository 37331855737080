export const contactPhoneNumbers = ["021 555 91 30", "0720 134 652"];

export const visuallyFormatPhoneNumber = (string) => {
  const glued = string.split(" ").join("");
  return glued.slice(0,3) + "-" + glued.slice(3, 6)+"." + glued.slice(6,8) + "." + glued.slice(8, 10);
  }

export const allExtraPoints = [
  {
    id: "pastrare-roba",
    content: "Roba se păstrează",
    price: 40,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "inchiriere-roba",
    content: "Roba se închiriază",
    price: 25,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "pastrare-esarfa-personalizata",
    content: "Eșarfa personalizată se păstrează",
    price: 13,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "pastrare-toca-standard",
    content: (
      <>
        {`Toca de absolvire se păstrează`}
        <br></br>
        {`(include bentiță și ciucure la alegere)`}
      </>
    ),
    price: 24,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "pastrare-toca-personalizata",
    content: (
      <>
        {/* {`Toca personalizată - cu diferit design`} */}
        {`Toca personalizată se păstrează`}
        <br></br>
        {`(include bentiță și ciucure la alegere)`}
      </>
    ),
    price: 29,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "personalizare-toca",
    content: <>{`Personalizare toca`}</>,
    price: 5,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "cocarda-individuala",
    content: "Cocardă individuală",
    price: 5,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "banut-ciucure-an-absolvire",
    content: (
      <>
        {`Bănuț cu anul absolvirii ”${new Date().getFullYear()}”`}
        <br></br>
        {`(pentru ciucure)`}
      </>
    ),
    price: 5,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "diplome-individuale-standard",
    content: "Diplome individuale",
    price: 2.5,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "diplome-onorifice-profesori",
    content: "Diplome onorifice pentru profesori",
    price: 2,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "diplome-individuale-piele",
    content: "Diplome individuale pe suport de piele",
    price: 10,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "invitatii-profesori",
    content: "Invitații profesori",
    price: 2,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "medalie-individuala",
    content: "Medalie individuala, pentru fiecare absolvent",
    price: 8,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "sucuri-fursecuri-snacksuri",
    content: "Sucuri, fursecuri și snaksuri",
    price: 15,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "trofeu-sef-clasa-promotie",
    content: "Trofeu șef clasă/șef promoție",
    price: 0,
    currency: "GRATIS",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "medalie-sef-clasa-promotie",
    content: "Medalie șef clasa/șef promoție",
    price: 0,
    currency: "GRATIS",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "placheta-diriginte-director-decan",
    content: (
      <>
        {`Plachetă pentru diriginte,`}
        <br></br>
        {`director sau decan`}
      </>
    ),
    price: 0,
    currency: "GRATIS",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "asistenta-ajutor",
    content: "Asistență și ajutor",
    price: 0,
    currency: "GRATIS",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "fotograf-festivitate",
    content: (
      <>
        {`Fotograf pentru festivitate`}
        <br></br>
        {`(minim 50 absolvenți)`}
      </>
    ),
    price: 20,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "cameraman-festivitate",
    content: (
      <>
        {`Cameraman pentru festivitate`}
        <br></br>
        {`(minim 50 absolvenți)`}
      </>
    ),
    price: 20,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "sedinta-foto-cu-recuzita",
    content: (
      <>
        {`Sedinta foto inedita cu recuzita`}
        <br></br>
        {`accesorii amuzante, masti, palarii`}
        <br></br>
        {`(minim 50 absolvenți)`}
      </>
    ),
    price: 15,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "trandafir-individual",
    content: (
      <>
        {`Trandafir individual`}
        <br></br>
        {`(minim 50 absolvenți)`}
      </>
    ),
    price: 10,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
  {
    id: "sampanie-piscoturi",
    content: "Șampanie și pișcoturi",
    price: 12,
    currency: "lei",
    isSelectable: true,
    isSelected: false,
  },
];

export const standardOffer = {
  id: "standard-offer",
  title: "Standard",
  price: 35,
  currency: "RON",
  points: [
    allExtraPoints.find((p) => p.id === "pastrare-esarfa-personalizata"),
    allExtraPoints.find((p) => p.id === "pastrare-toca-standard"),
    allExtraPoints.find((p) => p.id === "cocarda-individuala"),
  ],
  // This makes sure that each point is not deep copied into the new object
  extraPoints: allExtraPoints
    .map((x) => {
      return { ...x };
    })
    .filter(
      (p) =>
        p.id !== "inchiriere-roba" &&
        p.id !== "pastrare-roba" &&
        p.id !== "pastrare-toca-personalizata" &&
        p.id !== "trofeu-sef-clasa-promotie" &&
        p.id !== "medalie-sef-clasa-promotie" &&
        p.id !== "placheta-diriginte-director-decan" &&
        p.id !== "asistenta-ajutor"
    ),
};

export const premiumOffer = {
  id: "premium-offer",
  title: "Premium",
  price: 44,
  currency: "RON",
  points: [
    allExtraPoints.find((p) => p.id === "inchiriere-roba"),
    allExtraPoints.find((p) => p.id === "pastrare-esarfa-personalizata"),
    allExtraPoints.find((p) => p.id === "pastrare-toca-standard"),
    allExtraPoints.find((p) => p.id === "cocarda-individuala"),
    allExtraPoints.find((p) => p.id === "trofeu-sef-clasa-promotie"),
    allExtraPoints.find((p) => p.id === "medalie-sef-clasa-promotie"),
    allExtraPoints.find((p) => p.id === "placheta-diriginte-director-decan"),
    allExtraPoints.find((p) => p.id === "asistenta-ajutor"),
  ],
  // This makes sure that each point is not deep copied into the new object
  extraPoints: allExtraPoints
    .map((x) => {
      return { ...x };
    })
    .filter((p) => p.id !== "pastrare-roba" && p.id !== "pastrare-toca-personalizata"),
};

export const allInclusiveOffer = {
  id: "all-inclusive-offer",
  title: "All Inclusive",
  price: 65,
  currency: "RON",
  points: [
    allExtraPoints.find((p) => p.id === "pastrare-roba"),
    allExtraPoints.find((p) => p.id === "pastrare-esarfa-personalizata"),
    allExtraPoints.find((p) => p.id === "pastrare-toca-standard"),
    allExtraPoints.find((p) => p.id === "cocarda-individuala"),
    allExtraPoints.find((p) => p.id === "trofeu-sef-clasa-promotie"),
    allExtraPoints.find((p) => p.id === "medalie-sef-clasa-promotie"),
    allExtraPoints.find((p) => p.id === "placheta-diriginte-director-decan"),
    allExtraPoints.find((p) => p.id === "asistenta-ajutor"),
  ],
  // This makes sure that each point is not deep copied into the new object
  extraPoints: allExtraPoints
    .map((x) => {
      return { ...x };
    })
    .filter((p) => p.id !== "inchiriere-roba" && p.id !== "pastrare-toca-personalizata"),
};

export const flexibleOffer = {
  id: "flexible-offer",
  title: "Flexibil",
  price: 0,
  currency: "RON",
  points: [],
  // This makes sure that each point is not deep copied into the new object
  extraPoints: allExtraPoints
    .map((x) => {
      return { ...x };
    })
    .filter((p) => p.id !== "personalizare-toca"),
};

export const reviews = [
  {
    photo: require("./../assets/reviews/NituMihai.webp"),
    name: "Nitu Mihai",
    year: "2023",
    review: `Sunt foarte frumoase robele!! 🤩 Mi-au întrecut așteptările!! 🌟 Luni dimineață le trimit înapoi, , cu toată recunoștința mea.
    Am vorbit cu băieții de la Cargus și au spus că degeaba le pun sâmbătă, pentru că oricum tot luni pleacă. 🚚💨"
     Apreciez flexibilitatea voastră și înțelegerea în această privință`,
  },
  {
    photo: require("./../assets/reviews/MihaiBogdan.webp"),
    name: "Mihai Bogdan",
    year: "2022",
    review: `Colaborarea mea îndelungată cu echipa voastră a fost întotdeauna remarcabilă 🌟. Am experimentat servicii de la diverse companii în trecut, dar niciuna nu s-a comparat cu seriozitatea și calitatea excepțională pe care am găsit-o aici 💼👍. Ceea ce m-a impresionat profund este punctualitatea voastră constantă în livrări 🕒, un detaliu critic pentru mine.`,
  },
  {
    photo: require("./../assets/reviews/Tina.webp"),
    name: "Tina",
    year: "2021",
    review: `Draga mea MONICA, încă nu îmi găsesc cuvintele pentru a-ți mulțumi... pentru tot ceea ce ai făcut tu pentru mine....

    A fost una dintre cele mai fericite și împlinite zile din viața mea.... Și asta ți se datorează....
    
    Totul a ieșit la SUPERLATIV....
    
    Absolut toți au rămas fără cuvinte..... Pur și simplu.... Atât absolvenți, cât și profesori.....
    
    Sper ca într-o zi.... Să ne cunoaștem...TU AI FOST MINUNEA MEA.... 👼🏻ÎNGERAȘUL MEU PROTECTOR.... NU TE CUNOSC, DAR SIMT CĂ TE IUBESC. ❤
    
    Oricum a fost o zi de VIS....
    
    Au fost toți încântați, iar ideea cu TROFEUL pentru dna DIRECTOARE.... A fost colosală..... A plâns.... A mișcat-o...
    
    A plâns și mi-a recunoscut... că în viața ei nu a primit așa ceva...
    
    Înțelegi...... A avut o valoare inestimabilă....
    `,
  },
  {
    photo: require("./../assets/reviews/Vlad.webp"),
    name: "Vlad Doru",
    year: "2020",
    review: `Pachetul 'All Inclusive Plus' a fost o alegere excelentă pentru mine, oferindu-mi tot ce aveam nevoie pentru a face ziua și mai specială, de la medalia individuală până la placheta pentru diriginte. Aprecierea mea specială pentru opțiunea de personalizare a tocii, care a adăugat o notă unică întregii experiențe.
    Nu pot să nu menționez cât de ușor a fost să înregistrez comanda și cât de utilă a fost consultanța voastră în pregătirea pentru eveniment. Flexibilitatea și adaptabilitatea voastră la cerințele mele specifice au făcut tot procesul lipsit de stres.
    În concluzie, vreau să vă mulțumesc întregii echipe pentru seriozitatea, profesionalismul și calitatea serviciilor oferite. Ați transformat absolvirea mea într-o amintire superbă, pe care o voi prețui mereu. Voi recomanda cu căldură serviciile voastre tuturor celor care sunt in an terminal. 🎓
    `,
  },
];
