import { useEffect, useState, useCallback } from "react";
import "./contact-form.scss";
import { FieldValues, useForm } from "react-hook-form";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { contactPhoneNumbers } from "../../utils/constants";
import Button from "../button/button";

function ContactForm({ onSubmit, onDismiss, isValidated = () => {} }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
    reset,
    setValue,
    setError,
  } = useForm({ mode: "onChange" });
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [suggestSpeaker, setSuggestSpeaker] = useState("");
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [suggestSpeakerInput, setSuggestSpeakerInput] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    console.log("errors", errors);
    if (emailInput && phoneInput && messageInput) {
      isValidated(true);
      setIsFormValid(true);
    } else {
      isValidated(false);
      setIsFormValid(false);
    }
  }, [emailInput, messageInput, suggestSpeaker, suggestSpeakerInput, isValidated, errors, phoneInput]);

  const submit = async (args) => {
    try {
      setIsSendingMessage(true);
      console.log("Submited", args);
      const functions = getFunctions();
      console.log("functions", functions);
      const message = {
        name: args?.nameInput,
        email: args?.emailInput,
        phone: args?.phoneInput,
        message: args?.messageInput,
        suggestedSpeaker: args?.suggestSpeakerInput,
      };
      await httpsCallable(functions, "sendContactForm")(message);
      onSubmit(args);
      toast(`Mesaj trimis cu success !`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });
    } catch (err) {
      console.error(err);
      toast("Nu am putut trimite mesajul !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "dark",
      });
    } finally {
      setIsSendingMessage(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="">
        <div className="w-100 d-flex gap-1 flex-direction-column">
          <div>
            <h1>Doresti o colaborare cu noi, sau o absolvire de neuitat?</h1>
            <div>
              {`Sună-ne la `}
              <span>
                <a className="text-white" href={`tel:${contactPhoneNumbers[0]}`} alt="phone-number">
                  {`${contactPhoneNumbers[0]}`}
                </a>
              </span>
              {` sau `}
              <span>
                <a className="text-white" href={`tel:${contactPhoneNumbers[1]}`} alt="phone-number">
                  {`${contactPhoneNumbers[1]}`}
                </a>
              </span>

            </div>
            <h1>Sau scrie-ne un mesaj:</h1>
          </div>
          <div className="text-left">
            <label className="" htmlFor="nameInput">
              <div className={`${errors?.nameInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Nume`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="nameInput"
                value={nameInput}
                {...register("nameInput", { validate: true })}
                onChange={(e) => {
                  setNameInput(e.target.value);
                  setValue("nameInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left">
            <label className="" htmlFor="emailInput">
              <div className={`${errors?.emailInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`E-mail`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="emailInput"
                value={emailInput}
                {...register("emailInput", { validate: true })}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setValue("emailInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left">
            <label className="" htmlFor="phoneInput">
              <div className={`${errors?.phoneInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Nr. Telefon`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="phoneInput"
                value={phoneInput}
                {...register("phoneInput", { validate: true })}
                onChange={(e) => {
                  setPhoneInput(e.target.value);
                  setValue("phoneInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left mt-2">
            <label className="" htmlFor="messageInput">
              <div className={`${errors?.messageInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Mesaj`}</div>
              </div>
              <textarea
                className="mt-1 customInput w-100"
                id="messageInput"
                rows="4"
                cols="50"
                value={messageInput}
                {...register("messageInput", { validate: true })}
                onChange={(e) => {
                  setMessageInput(e.target.value);
                  setValue("messageInput", e.target.value, { shouldValidate: true });
                }}></textarea>
            </label>
          </div>

          <div className="text-sm mt-2">
            {`* Apăsând pe butonul "Trimite" îți dai consimțământul că esți de acord cu `}{" "}
            <a className="text-black" target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
              Politica de Confidențialitate
            </a>
          </div>
        </div>
        <div></div>
        <div className={`input-error ${errors.length ? "" : "invisible"}`}>{"Completează toate câmpurile !"}</div>

        <div className="d-flex justify-content-between mt-2 mb-2">
          {/* <div
                className={"button cancel-button flex-1 d-flex justify-content-center"}
                onClick={() => {
                  onDismiss();
                }}>
                {"Cancel"}
              </div> */}
          {/* <div
                className={`p-relative button confirm-button flex-1 d-flex justify-content-center ${
                  isFormValid ? "" : "disabled"
                } ${
                  isSendingMessage ? "disabled" : ""
                }`}>
                {isSendingMessage ? "Se trimite..." : "Trimite"}

                {
                  !isSendingMessage ? <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input> : <></>
                }
                

              </div> */}

          <Button
            scale="0.8"
            onClick={() => {
              onDismiss();
            }}
            type="full"
            color="primary"
            content={
              <div className="d-flex flex-wrap justify-content-center gap-1">
                <div>{`Anulează`}</div>
              </div>
            }
          />

          <Button
            scale="0.8"
            color="white"
            className={`p-relative justify-content-center ${isSendingMessage ? "disabled" : ""} ${
              isFormValid ? "" : "disabled"
            }`}
            content={
              <div className="d-flex flex-wrap justify-content-center gap-1">
                <div>{isSendingMessage ? "Se trimite..." : `Trimite`}</div>
                {!isSendingMessage ? (
                  <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input>
                ) : (
                  <></>
                )}
              </div>
            }
          />
        </div>
      </form>
    </>
  );
}
export default ContactForm;
