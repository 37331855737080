import "./legal.scss";
import Footer from "../../components/footer/footer";

function TermsConditions() {
  return (
    <div className="Home">
      <div className="p-relative mt-7">
        <div className="container">
          <h1 className="h0">Termeni și condiții pentru 'https://absolvirea.ro/'</h1>

          <div className="mt-4">
            <b>{`1. Introducere`}</b>
          </div>

          <div className="mt-2">
            {`
Bine ați venit pe www.absolvirea.ro, un website dedicat oferirii de robe de absolvire și accesorii conexe de absolvire. Prin accesarea și utilizarea acestui website, vă exprimați acordul cu următorii termeni și condiții („Termeni”). Dacă nu sunteți de acord cu acești Termeni, vă rugăm să nu utilizați site-ul.
`}
          </div>

          <div className="mt-4">
          <b>{`2. Drepturi de Proprietate Intelectuală`}</b>
          </div>
        
          <div className="mt-2">
            {`
Conținutul site-ului, inclusiv textele, grafica, logo-urile și imaginile, sunt proprietatea www.absolvirea.ro sau a furnizorilor săi de conținut și sunt protejate de legile dreptului de autor și de alte legi referitoare la proprietatea intelectuală. Utilizarea neautorizată a oricărui material de pe site este strict interzisă.
`}
          </div>


          <div className="mt-4">
          <b>{`3. Utilizarea Site-ului`}</b>
          </div>
        
          <div className="mt-2">
            {`
Site-ul este destinat utilizatorilor care caută informații și doresc să achiziționeze robe de absolvire și accesorii conexe. Orice utilizare abuzivă a site-ului, inclusiv, dar fără a se limita la, încălcarea securității sale, utilizarea sistemelor automate pentru extragerea datelor (scraping) sau utilizarea site-ului pentru scopuri ilegale, este interzisă.
`}
          </div>

          <div className="mt-4">
          <b>{`4. Schimbări ale Serviciului și Termenilor`}</b>
          </div>
        
          <div className="mt-2">
            {`
www.absolvirea.ro își rezervă dreptul de a modifica sau întrerupe temporar sau permanent serviciile oferite, cu sau fără notificare prealabilă. De asemenea, www.absolvirea.ro își rezervă dreptul de a modifica acești Termeni oricând, iar modificările vor deveni efective imediat după publicarea lor pe site.
`}
          </div>

          <div className="mt-4">
          <b>{`5. Politica de Returnare`}</b>
          </div>
        
          <div className="mt-2">
            {`
www.absolvirea.ro oferă si o  politică de returnare. Pentru o cerere de retur va rugam sa ne trimiteti o solicitare scrisa la adresa contact@absolvirea.ro
`}
          </div>

          <div className="mt-4">
          <b>{`6. Limitarea Răspunderii`}</b>
          </div>
        
          <div className="mt-2">
            {`
www.absolvirea.ro nu va fi răspunzător pentru orice daune directe, indirecte, accidentale, speciale sau consecvențiale rezultate din utilizarea sau incapacitatea de a utiliza serviciile sau produsele oferite.
`}
          </div>

          <div className="mt-4">
          <b>{`7. Legături către Site-uri Terțe`}</b>
          </div>
        
          <div className="mt-2">
            {`
Site-ul poate conține legături către site-uri terțe care nu sunt deținute sau controlate de www.absolvirea.ro nu își asumă responsabilitatea pentru conținutul, politicile de confidențialitate sau practicile site-urilor terțe.
`}
          </div>

          <div className="mt-4">
          <b>{`8. Jurisdicție și Legi Aplicabile`}</b>
          </div>
        
          <div className="mt-2">
            {`
Termenii și condițiile de utilizare a site-ului sunt guvernate și interpretate conform legislației Romane iar orice litigiu rezultat din sau în legătură cu acești Termeni va fi solicitate pe cale amiabila.
`}
          </div>

          <div className="mt-4">
          <b>{`9. Contact`}</b>
          </div>
        
          <div className="mt-2">
            {`
Pentru orice întrebări sau nelămuriri legate de Termeni și Condiții, vă rugăm să ne contactați la adresa de email contact@absolvrea.ro
`}
          </div>

        </div>
      </div>

      <div className="p-relative mt-7">
        <Footer />
      </div>
    </div>
  );
}

export default TermsConditions;
