import { useEffect, useState, useCallback } from "react";
import "./finish-order-form.scss";
import { FieldValues, useForm } from "react-hook-form";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { contactPhoneNumbers } from "../../utils/constants";
import Button from "../button/button";

function FinishOrderForm({ onSubmit, onDismiss, isValidated = () => {}, orderData }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
    reset,
    setValue,
    setError,
  } = useForm({ mode: "onChange" });
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [numberOfStudentsInput, setNumberOfStudentsInput] = useState();

  const [messageInput, setMessageInput] = useState("");
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSendingOrder, setIsSendingOrder] = useState(false);

  useEffect(() => {
    console.log("errors", errors);
    if (emailInput && phoneNumberInput && nameInput) {
      isValidated(true);
      setIsFormValid(true);
    } else {
      isValidated(false);
      setIsFormValid(false);
    }
  }, [emailInput, messageInput, phoneNumberInput, isValidated, errors, nameInput]);

  const cleanJSXContent = (content) => {
    if(content?.type){
      const newContent = content?.props?.children?.map(o=>cleanJSXContent(o)).filter(o=>o !== undefined);
      if(newContent){
        return newContent + " ";
      }else return "";
    }else return content + " ";
  };

  const cleanupOrderData = (orderData) => {
    const clean = {
      ...orderData,
      extraPoints: orderData.extraPoints
        .filter((x) => x.isSelected && x.isSelectable)
        .map((p) => {
          const cleanP = {
            ...p,
            content: cleanJSXContent(p.content)
          }
          delete cleanP.isSelectable; 
          delete cleanP.isSelected; 
          return cleanP;
        }),
      points: orderData.points
      .map((p) => {
        const cleanP = {
          ...p,
          content: cleanJSXContent(p.content)
        }
        delete cleanP.isSelectable; 
        delete cleanP.isSelected; 
        return cleanP;
      }),
  };

    return clean;
  };

  const submit = async (args) => {
    try {
      setIsSendingOrder(true);
      console.log("Submited", args);
      const functions = getFunctions();
      console.log("functions", functions);
      const order = {
        name: args?.nameInput,
        email: args?.emailInput,
        phoneNumber: args?.phoneNumberInput,
        numberOfStudents: args?.numberOfStudentsInput,
        message: args?.messageInput,
        orderData: cleanupOrderData(orderData),
      };
      console.log("clean order", order);
      await httpsCallable(functions, "sendOrder")(order);
      onSubmit(args);
      toast(`Comandă trimisă cu success !`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
      });
    } catch (err) {
      console.error(err);
      toast("Nu am putut trimite comanda !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
      });
    } finally {
      setIsSendingOrder(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="">
        <div className="w-100 d-flex gap-1 flex-direction-column">
          <h2 className="text-center">Lasă-ne datele tale pentru a finaliza comanda</h2>

          <div className="text-left">
            <label className="" htmlFor="nameInput">
              <div className={`${errors?.nameInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Nume *`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="nameInput"
                value={nameInput}
                {...register("nameInput", { validate: true })}
                onChange={(e) => {
                  setNameInput(e.target.value);
                  setValue("nameInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left">
            <label className="" htmlFor="emailInput">
              <div className={`${errors?.emailInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`E-mail *`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="emailInput"
                value={emailInput}
                {...register("emailInput", { validate: true })}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setValue("emailInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left">
            <label className="" htmlFor="phoneNumberInput">
              <div className={`${errors?.phoneNumberInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Număr de telefon *`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="phoneNumberInput"
                value={phoneNumberInput}
                {...register("phoneNumberInput", { validate: true })}
                onChange={(e) => {
                  setPhoneNumberInput(e.target.value);
                  setValue("phoneNumberInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left">
            <label className="" htmlFor="numberOfStudentsInput">
              <div className={`${errors?.numberOfStudentsInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Număr aproximativ de absolvenți`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="numberOfStudentsInput"
                type="number"
                value={numberOfStudentsInput}
                {...register("numberOfStudentsInput", { validate: false })}
                onChange={(e) => {
                  setNumberOfStudentsInput(e.target.value);
                  setValue("numberOfStudentsInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left mt-2">
            <label className="" htmlFor="messageInput">
              <div className={`${errors?.messageInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Mesaj`}</div>
              </div>
              <textarea
                className="mt-1 customInput w-100"
                id="messageInput"
                rows="4"
                cols="50"
                value={messageInput}
                {...register("messageInput", { validate: true })}
                onChange={(e) => {
                  setMessageInput(e.target.value);
                  setValue("messageInput", e.target.value, { shouldValidate: true });
                }}></textarea>
            </label>
          </div>

          <div className="text-sm mt-2">
            {`* Apăsând pe butonul "Trimite" îți dai consimțământul că esți de acord cu `}{" "}
            <a target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
              Politica de Confidențialitate
            </a>
          </div>
        </div>
        <div></div>
        <div className={`input-error ${errors.length ? "" : "invisible"}`}>{"Completează toate câmpurile !"}</div>

        <div className="d-flex justify-content-between mt-2 mb-2 align-items-center">
          <Button
            scale="0.8"
            onClick={() => {
              onDismiss();
            }}
            type="outline"
            content={
              <div className="d-flex flex-wrap justify-content-center gap-1">
                <div>{`Anulează`}</div>
              </div>
            }
          />
          <Button
            scale="0.8"
            className={`p-relative ${isSendingOrder ? "disabled" : ""} ${isFormValid ? "" : "disabled"}`}
            content={
              <div className="d-flex flex-wrap justify-content-center gap-1">
                <div>
                  {isSendingOrder ? "Se comandă..." : `Comandă - ${orderData?.grandTotal} ${orderData?.currency}`}
                </div>
                {!isSendingOrder ? (
                  <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input>
                ) : (
                  <></>
                )}
              </div>
            }
          />
        </div>
      </form>
    </>
  );
}
export default FinishOrderForm;
