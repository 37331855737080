import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import SimpleHero from "../../components/simple-hero/simple-hero";
import { contactPhoneNumbers } from "../../utils/constants";

function CocardeAbsolvire() {
  return (
    <div className="Home">
      <div id="top"></div>
      <SimpleHero
        title={
          <>
            Cocarde Absolvire <br></br> Inchiriaza sau Cumpara
          </>
        }
        image={require("./../../assets/banners/4.png")}
      />

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
          Cocarda absolvire pentru momente speciale. Pentru o festivitate unică!
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
              Se apropie cel mai frumos moment. Acel moment care marchează sfârșitul unei etape frumoase din viața unui elev sau student, dar și începutul alteia la fel sau poate mai frumoase. 

              `}
            </div>
            <div className="p1 mt-2">
              {`
            Fie că ești un părinte care vrea să-i ofere celui mic momente speciale sau ești un student aflat în căutare de cea mai frumoasă ținută pentru festivitatea de absolvire, te afli în momentul potrivit, la locul potrivit. Completează ținuta cu o cocarda pentru absolvire, alegând de pe absolvirea.ro dintr-o gamă variată de culori, pentru elevi sau studenți, pe care le poți personaliza. 
           `}
            </div>
            <div className="p1 mt-2">
              {`
            Cocarda este acel semn distinctiv, cunoscută sub denumirea de insignă sau eșarfă, pe care-l adaugi ținutei de absolvire pe care o poți personaliza cu un text specific și pe care o poți găsi pe site-ul nostru într-o varietate cromatică deosebită, confecționate din materiale de calitate, cu un sistem de prindere care te asigură că nu părăsește ținuta ta deosebită.

            `}
            </div>
            <div className="p1 mt-2 font-bold">
              {`
              Alege culoarea ta preferată de cocardă pentru marcarea unui rezultat deosebit!
            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/cocarde/1.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">Cocarde pentru absolvire, din calitate superioară, la preț redus</h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
              Alege oricare dintre produsele care îți plac, iar noi îți garantăm calitatea, prețul redus și livrarea promptă, oriunde te-ai afla pe teritoriul țării.
            `}
            </div>
            <div className="p1 mt-2">
              {`
              Ai nevoie de consultanță pentru o festivitate de vis? Nicio problemă. Suntem aici pentru tine, pentru a-ți oferi sfaturile de care ai nevoie și pentru a ne adapta dorințelor tale. Pentru ca totul să fie pefect.
            `}
            </div>

            <div className="p1 mt-2 font-bold">
              {`
              Comandă oricare dintre cocardele noastre și bucură-te de momente unice!
            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/cocarde/2.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>
      <div className="p-relative container mt-3 mt-lg-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button content={<>{contactPhoneNumbers[0]}</>} width="400px" type="outline" classes="" link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      <div className="p-relative mt-3 mt-lg-5">
        <Footer />
      </div>
    </div>
  );
}

export default CocardeAbsolvire;
