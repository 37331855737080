import "./offer-item-point.scss";

function OfferItemPoint({ content, price = 0, currency = "", isSelectable = true }) {
  return (
    <div className={`offer-item-point-container`}>
      <div className="item-content">{content}</div>
      <div className="item-dash"></div>

      <div className="">
        {isSelectable ? (
          <>
            <div className="d-flex gap-1 item-price">
              <div className="">{price ? `+${price}` : ""}</div>
              <div className="">{currency}</div>
            </div>
          </>
        ) : (
          <>
            <div className="item-price">Inclus</div>
          </>
        )}
      </div>
    </div>
  );
}

export default OfferItemPoint;
