import Button from "../button/button";
import ButtonHero from "../button-hero/button-hero";
import "./hero.scss";
import { contactPhoneNumbers } from "../../utils/constants";
import { goToPhoneOrContactDependingOnDevice } from "../../utils/utils";
import { useContext } from "react";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../modals/alert-modal/alert-modal";

function Hero() {
  const { handleModal } = useContext(ModalContext);

  const openContactModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={() => {}}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  return (
    <div className="hero-container">
      <div className="hero-background">
        <img alt="absolvirea-banner" className="w-100" src={require("./../../assets/banner.webp")}></img>
      </div>
      <header className="p-relative h-100 container text-white d-flex justify-content-evenly align-items-center flex-direction-column">
        <div className="d-flex justify-content-evenly align-items-center flex-direction-column animate__animated animate__fadeIn">
          <h1 className="font-hero hero-heading text-center mt-2 mt-md-6">
            <div className="mb-0">
              Robe Absolvire <br></br> si <br></br> Toci Absolvire
            </div>
          </h1>
        </div>
        <div className="w-100 pl-1 gap-3 p-relative d-flex flex-wrap justify-content-center align-items-center animate__animated animate__fadeIn">
          <ButtonHero
            content="Comandă acum"
            is3d={true}
            isPulsing={true}
            width="400px"
            color={"primary-white"}
            link="/#pachete"></ButtonHero>

          <Button
            content={<>{contactPhoneNumbers[0]}</>}
            width="400px"
            type="full"
            color="white"
            className="pt-0 pt-md-2"
            onClick={() => goToPhoneOrContactDependingOnDevice(openContactModal)}></Button>
        </div>
        <div className="mt-0 mt-md-4 h3 text-center animate__animated animate__fadeIn">
          <div>Închiriem și Organizăm</div>
          <div>Festivități pentru toate unitățile de învățământ</div>
        </div>
      </header>
    </div>
  );
}

export default Hero;
