import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import PackageFrame1 from "../../components/package-frames/package-frame-1/packageFrame1";
import PackageFrame2 from "../../components/package-frames/package-frame-2/packageFrame2";
import PackageFrame3 from "../../components/package-frames/package-frame-3/packageFrame3";
import ResponsivePackageFrame from "../../components/package-frames/responsive-package-frame/responsivePackageFrame";
import SimpleHero from "../../components/simple-hero/simple-hero";
import {
  allExtraPoints,
  standardOffer,
  premiumOffer,
  allInclusiveOffer,
  flexibleOffer,
  reviews,
  contactPhoneNumbers,
} from "../../utils/constants";
import { delay } from "../../utils/utils";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";
import { ModalContext } from "../../utils/modal/modalContext";
import { useContext } from "react";

function PacheteAbsolvire() {
  const { handleModal } = useContext(ModalContext);

  const offers = [
    {
      ...standardOffer,
      extraPoints: standardOffer.extraPoints.map((ep) => {
        if (standardOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
          ep.isSelectable = false;
          ep.isSelected = true;
        }
        return ep;
      }),
    },
    {
      ...premiumOffer,
      extraPoints: premiumOffer.extraPoints.map((ep) => {
        if (premiumOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
          ep.isSelectable = false;
          ep.isSelected = true;
        }
        return ep;
      }),
    },
    {
      ...allInclusiveOffer,
      extraPoints: allInclusiveOffer.extraPoints.map((ep) => {
        if (allInclusiveOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
          ep.isSelectable = false;
          ep.isSelected = true;
        }
        return ep;
      }),
    },
    {
      ...flexibleOffer,
      extraPoints: flexibleOffer.extraPoints.map((ep) => {
        if (flexibleOffer.points.findIndex((p) => p.id === ep.id) !== -1) {
          ep.isSelectable = false;
          ep.isSelected = true;
        }
        return ep;
      }),
    },
  ];
  
  const openOrderFinalizationModal = (orderData) => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.FinishOrder}
          className="modal-wide"
          title={`Finalizează comanda - ${orderData.title}`}
          successLabel="Trimite comanda"
          cancelLabel="Anulează"
          extraData={orderData}
          onDismiss={() => {}}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  const orderPackage = async (orderData, withDelay = true) => {
    console.log("orderPackage", orderData);
    if (!orderData?.extraOptionsSubtotal) {
      orderData.extraOptionsSubtotal = 0;
      orderData.grandTotal = orderData.price;
    }
    if (withDelay) {
      await delay(1000);
    }
    await openOrderFinalizationModal(orderData);
  };
  return (
    <div className="Home">
      <div id="top"></div>
      <SimpleHero
        title={
          <>
            Pachete absolvire <br></br> Liceu, facultate sau generala
          </>
        }
        image={require("./../../assets/banners/1.png")}
      />

      <div className="p-relative container mt-5">
        <h2 className="h1 section-divider-title">
          Alegeți un pachet absolvire liceu, facultate sau gimnaziu în funcție de preferințele și nevoile tale
        </h2>
        <div className="half-half mt-2 gap-3">
          <div className="">
            <div className="p1 mt-2">
              {`
            Ai visat întotdeauna să-ți închei studiile cu stil și eleganță?
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Te pregătești de absolvire și vrei ca acest eveniment important din viața ta să fie memorabil?
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Ai nevoie de un pachet complet care să includă tot ce îți trebuie pentru a avea o apariție de neuitat și pentru a te simți special?
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Ei bine, noi suntem aici să îți oferim cel mai bun pachet de absolvire - un pachet de absolvire care să îți satisfacă toate nevoile și dorințele. Dacă ai nevoie de un pachet de absolvire complet și personalizat, care să reflecte individualitatea și excelența ta, atunci tot ce ai de făcut este să alegi pachetul potrivit pentru tine.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pe absolvirea.ro îți punem la dispoziție o varietate de pachete pentru ca tu să alegi acel pachet de absolvire liceu sau facultate de care ai nevoie.
            `}
            </div>
          </div>
          <div className="">
            <div className="p1 mt-2">
              {`
            Pachetul All Inclusive Plus , include tot ce îți trebuie pentru a arăta absolut fantastic la ceremonia de absolvire. Îți garantăm că vei fi in centrul atenției cu roba de absolvire, eșarfa personalizată și toca de absolvire cu bentiță și ciucure la alegere. De asemenea, vei primi o medalie individuală, trofeu pentru șef de clasă sau șef de promoție, diplome, cocarde, invitații și plachetă pentru diriginte, director sau decan. Și asta nu e tot! Îți oferim și asistență și ajutor în cazul în care ai nevoie.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Și dacă îți dorești o toca personalizată Premium, o poți obține cu doar 5 lei în plus.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pachetul All Inclusiv, include toate elementele esențiale pentru a avea o prezență deosebită la ceremonia de absolvire. În plus, poți opta pentru pachetele Premium și Premium Plus, care includ și o medalie individuală pentru fiecare absolvent.
            `}
            </div>
            <div className="p1 mt-2">
              {`
            Pachetul Flexibil care poate fi personalizat în funcție de preferințele tale. Vrei să închiriezi roba sau să o păstrezi? Vrei să închiriezi sau să păstrezi toca sau eșarfa? Vrei să ai albume foto, diplome și invitații personalizate, fotografii și videoclipuri, sau să ai accesorii precum cocarde, medalii, plachete și trofee, flori și aranjamente florale, băuturi precum șampanie, sucuri și pișcoturi sau alte accesorii și servicii?
            `}
            </div>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button content={<>{contactPhoneNumbers[0]}</>} width="400px" type="outline" classes="" link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>



      <div id="pachete" className="p-relative container mt-3">
        <div className="h1 section-divider-title">Oferta noastră</div>
        <div className="d-flex flex-wrap mt-3 gap-3 justify-content-around align-items-start">
          {/* {offers.map((offer, index) => {
            return (
              <div className="" key={"package-offer-item-" + index}>
                  <PackageFrame title={offer.title} content={offer.content}/>
              </div>
            );
          })} */}
          <PackageFrame1 offer={offers[0]} onSuccess={orderPackage} />
          <PackageFrame2 offer={offers[1]} onSuccess={orderPackage} />
          <img
            className="frame-replacement"
            alt={"Ornament absolvire"}
            src={require("./../../assets/frame-borders/graduation-ornament.webp")}
          />

          <PackageFrame3 offer={offers[2]} onSuccess={orderPackage} />
          <ResponsivePackageFrame offer={offers[3]} onSuccess={orderPackage} />
        </div>
      </div>

      <div className="p-relative container mt-5">
        <div className="text-center mt-2 w-100">
          <div className="">
            <div className="p1 mt-2">
              {`
            Nimic nu este prea mult sau prea puțin pentru noi. Ne asigurăm că îți oferim servicii rapide și de înaltă calitate și că fiecare pachet este adaptat la nevoile și dorințele tale. 
            Alege pachetul de absolvire care se potrivește cel mai bine cu preferințele tale și lasă-ne să te ajutăm să faci din ceremonia de absolvire un moment de neuitat!
            `}
            </div>
          </div>
        </div>
      </div>

      <div className="p-relative mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default PacheteAbsolvire;
