import { useContext, useEffect, useState } from "react";
import OfferItemPoint from "../offer-item-point/offer-item-point";
import "./packageExtraOptionsSelector.scss";
import { FieldValues, useForm } from "react-hook-form";
import Button from "../button/button";

function PackageExtraOptionsSelector({
  offer = {},
  onSubmit = () => {},
  onDismiss = () => {},
  isValidated = () => {},
}) {
  const { register, handleSubmit, setValue } = useForm({ mode: "onChange" });

  const [selectedPoints, setSelectedPoints] = useState(offer?.extraPoints || []);
  const [extraOptionsSubtotal, setExtraOptionsSubtotal] = useState(0);

  useEffect(() => {
    console.log("selectedPoints", selectedPoints);
    selectedPoints.forEach((p) => {
      setValue(`${p.id}`, p.isSelected, {
        shouldValidate: false,
      });
    });

    setExtraOptionsSubtotal(
      selectedPoints.reduce((accumulator, current) => {
        if (current?.isSelected && current?.isSelectable) accumulator += current?.price || 0;
        return accumulator;
      }, 0) || 0
    );
  }, [selectedPoints, setValue]);

  const order = async (args) => {
    const orderData = {
      ...offer,
      extraPoints: selectedPoints,
      extraOptionsSubtotal: extraOptionsSubtotal,
      grandTotal: extraOptionsSubtotal + offer.price
    }

    console.log("order", args);
    console.log("orderData", orderData);
    onSubmit(orderData);
  }

  return (
    <div className="extra-options-selector-container">
      {/* <div className="h1 color-black mb-3">Opțiuni posibile</div> */}

      <form onSubmit={handleSubmit(order)} className="">
        <div className="bullet-points-wrapper">
          <div className="bullet-points-column">
            {selectedPoints.slice(0, selectedPoints?.length / 2 + 2).map((point, index) => {
              return (
                <div
                  className={`mt-1 custom-checkbox-container ${point?.isSelectable ? "" : "no-interaction"}`}
                  key={`${point.id}`}>
                  <label className={`d-flex  ${point?.isSelectable ? "" : "no-interaction"}`} htmlFor={`${point.id}`}>
                    <input
                      className={`mr-2`}
                      id={`${point.id}`}
                      type="checkbox"
                      value={point?.isSelected ? 1 : 0}
                      {...register(`${point.id}`, { required: false })}
                      onChange={(e) => {
                        setSelectedPoints((points) =>
                          points.map((p) => {
                            if (p?.content === point?.content) p.isSelected = !!e.target.checked;
                            return p;
                          })
                        );
                        setValue(`${point.id}`, !!e.target.checked, {
                          shouldValidate: true,
                        });
                      }}></input>
                    <span className={`custom-checkmark`}></span>

                    <OfferItemPoint
                      content={point.content}
                      price={point.price}
                      currency={point?.currency}
                      isSelectable={point?.isSelectable}
                    />
                  </label>
                </div>
              );
            })}
          </div>
          <div className="bullet-points-column">
            {selectedPoints.slice(selectedPoints?.length / 2 + 2, selectedPoints?.length).map((point, index) => {
              return (
                <div
                  className={`mt-1 custom-checkbox-container ${point?.isSelectable ? "" : "no-interaction"}`}
                  key={`${point.id}`}>
                  <label className={`d-flex  ${point?.isSelectable ? "" : "no-interaction"}`} htmlFor={`${point.id}`}>
                    <input
                      className="mr-2 custom-checkbox"
                      id={`${point.id}`}
                      type="checkbox"
                      value={point?.isSelected ? 1 : 0}
                      {...register(`${point.id}`, { required: false })}
                      onChange={(e) => {
                        setSelectedPoints((points) =>
                          points.map((p) => {
                            if (p?.content === point?.content) p.isSelected = !!e.target.checked;
                            return p;
                          })
                        );
                        setValue(`${point.id}`, !!e.target.checked, {
                          shouldValidate: true,
                        });
                      }}></input>
                    <span className={`custom-checkmark`}></span>

                    <OfferItemPoint
                      content={point.content}
                      price={point.price}
                      currency={point?.currency}
                      isSelectable={point?.isSelectable}
                    />
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div className="divider-line mt-2 mb-2"></div>

        <div className="price-area-container">
          <div className="price-area">
            <div className="d-flex gap-2 justify-content-between">
              <div> {`Pachet ${offer?.title}: `}</div>
              <div>
                {` ${offer?.price}`}
                {` ${offer?.currency}`}
              </div>
            </div>

            <div className="d-flex gap-2 justify-content-between">
              <div>{"Extra opțiuni:"}</div>
              <div>
                {`+ `}
                {extraOptionsSubtotal}
                {` ${offer?.currency}`}
              </div>
            </div>

            <div className="d-flex gap-2 justify-content-between mt-2 h3">
              <div>{"Total:"}</div>
              <div>
                {extraOptionsSubtotal + (offer?.price || 0)}
                {` ${offer?.currency}`}
              </div>
            </div>
          </div>
        </div>


        <div className="d-flex justify-content-around align-items-center mt-2 mb-2">
          <Button
            scale="0.8"
            onClick={() => {
              onDismiss();
            }}
            type="outline"
            content={
              <div className="d-flex flex-wrap justify-content-center gap-1">
                <div>{`Închide`}</div>
              </div>
            }
          />
          <Button
            scale="0.8"
            className={`p-relative button flex-1 d-flex justify-content-center`}
            content={
              <div className="d-flex flex-wrap justify-content-center gap-1">
                <div>{`Comandă - ${extraOptionsSubtotal+offer?.price} ${offer?.currency}`}</div>
                <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input>
              </div>
            }
          />
        </div>
      </form>
    </div>
  );
}

export default PackageExtraOptionsSelector;
