import { useState, useContext } from "react";
import BurgerIcon from "../../animated-icons/burger-icon/burger-icon";
import NavMenuButton from "../nav-menu-button/nav-menu-button";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";
import "./nav-menu-bar.scss";
import { useLocation } from "react-router-dom";
import logoBanner from "./../../assets/logo-banner.png";

function NavMenuBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { handleModal } = useContext(ModalContext);

  const location = useLocation();

  const openContactModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={() => {}}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  return (
    <div className="nav-menu-bar">
      <div className="big-container d-flex">
        <div className="brand-identity">
          <a href="/#top">
            <img src={logoBanner} className="logo" alt="find-your-why-by-flony-motivational"></img>
          </a>
        </div>
        <div className="container pl-5 pr-5 nav-buttons-container">
          <NavMenuButton
            content="Acasă"
            link="/"
            isActive={location?.pathname === "/" || !location?.pathname?.length}></NavMenuButton>
          <NavMenuButton content="Pachete" link="/#pachete" isActive={false}></NavMenuButton>
          <NavMenuButton content="Toci" link="/toca-absolvire" isActive={location?.pathname?.includes("/toca-absolvire")}></NavMenuButton>
          <NavMenuButton content="Esarfe" link="/esarfe-absolvire" isActive={location?.pathname?.includes("/esarfe-absolvire")}></NavMenuButton>
          <NavMenuButton content="Cocarde" link="/cocarde-absolvire" isActive={location?.pathname?.includes("/cocarde-absolvire")}></NavMenuButton>
          <NavMenuButton content="Diplome" link="/diplome-de-absolvire" isActive={location?.pathname?.includes("/diplome-de-absolvire")}></NavMenuButton>
          <NavMenuButton content="Diplome Onorifice" link="/diplome-onorifice" isActive={location?.pathname?.includes("/diplome-onorifice")}></NavMenuButton>
          <NavMenuButton content="Contact" link="" isActive={false} onClick={openContactModal}></NavMenuButton>
        </div>
        <div onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="burger-menu-container">
          <div className={`burger-button animate-on-hover ${isMobileMenuOpen ? "burger-opened" : ""}`}>
            <BurgerIcon />
          </div>
          <div className={`mobile-menu ${isMobileMenuOpen ? "mobile-active" : ""}`}>
            <a id="nav/acasa" href="/" className="menu-item" tabIndex={0}>
              Acasă
            </a>
            <a id="nav/pachete" href="/#pachete" className="menu-item" tabIndex={0}>
              Pachete
            </a>
            <a id="nav/toca-absolvire" href="/toca-absolvire" className="menu-item" tabIndex={0}>
              Toci
            </a>
            <a id="nav/esarfe-absolvire" href="/esarfe-absolvire" className="menu-item" tabIndex={0}>
              Esarfe
            </a>
            <a id="nav/cocarde-absolvire" href="/cocarde-absolvire" className="menu-item" tabIndex={0}>
              Cocarde
            </a>
            <a id="nav/diplome-de-absolvire" href="/diplome-de-absolvire" className="menu-item" tabIndex={0}>
              Diplome
            </a>
            <a id="nav/diplome-onorifice" href="/diplome-onorifice" className="menu-item" tabIndex={0}>
              Diplome Onorifice
            </a>

            <div id="nav/Contact" onClick={openContactModal} className="menu-item" tabIndex={0}>
              Contact
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenuBar;
