import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import SimpleHero from "../../components/simple-hero/simple-hero";
import { contactPhoneNumbers } from "../../utils/constants";

function DiplomeDeAbsolvire() {
  return (
    <div className="Home">
      <div id="top"></div>
      <SimpleHero
        title={
          <>
            Diplome de Absolvire <br></br> Inchiriaza sau Cumpara
          </>
        }
        image={require("./../../assets/banners/6.png")}
      />

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">Diplome onorifice pentru profesori & elevi valoroși</h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
                     Festivitatea de absolvire este un moment special în viața fiecărui elev sau student. Este un moment de sărbătoare, de bucurie și de realizare, care trebuie marcat într-un mod special. Și o diplomă personalizată este exact ingredientul perfect pentru a face acest lucru.

              `}
            </div>
            <div className="p1 mt-2">
              {`
                     La fel ca și fiecare elev sau student, fiecare diplomă personalizată este unică și specială. Oferim o gamă variată de modele de diplome de absolvire, pe care le putem livra oriunde te-ai afla în țară. Indiferent de orașul sau de satul în care te afli, nu trebuie să îți faci griji cu privire la livrarea diplomei, deoarece îți vom trimite comanda direct la ușa ta.

           `}
            </div>
            <div className="p1 mt-2 font-bold">
              {`
              Orice elev sau student absolvent merită să se bucure de ziua absolvirii! Iar pentru ca bucuria să fie completă, diploma de absolvire trebuie să fie perfectă!
            `}
            </div>
          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/diplome/1.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>

      <div className="p-relative container mt-3 mt-lg-5">
        <h2 className="h1 section-divider-title">
        Model diploma de absolvire personalizat, pentru orice buget
        </h2>
        <div className="text-and-image-section mt-3">
          <div className="text-side gap-2">
            <div className="p1 mt-2">
              {`
                     Suntem mândri că îți punem la dispoziție o gamă variată de modele, la cele mai bune prețuri. Știm că mulți elevi sau studenți pot avea bugete limitate, dar asta nu ar trebui să fie un impediment pentru a avea o diplomă personalizată și emoționantă de absolvire. Prin urmare, ne asigurăm că prețurile noastre sunt accesibile și competitive.

            `}
            </div>
            <div className="p1 mt-2">
              {`
                       Iar dacă ai întrebări sau nelămuriri, nu trebuie decât să ne contactezi. Suntem aici pentru a-ți oferi asistență și consultanță pentru a face procesul de comandare cât mai ușor și mai plăcut posibil. Dorim ca totul să fie perfect pentru tine și să te bucuri de momentul special de absolvire.

            `}
            </div>

            <div className="p1 mt-2 font-bold">
              {`
              Comandă acum pentru a avea garanția că vei primi modelul de diplomă pentru absolvire la cele mai bune prețuri și în cel mai scurt timp.
            `}
            </div>

            <div className="p1 mt-2 font-bold">
              {`
            Așteptăm cu nerăbdare să te ajutăm să creezi din festivitatea de absolvire una memorabilă și emoționantă!
            `}
            </div>


          </div>
          <div className="image-side">
            <img className="right-side-content-image" src={require("./../../assets/content-images/diplome/2.webp")} width="100%" alt="toci absolvire"></img>
          </div>
        </div>
      </div>
      <div className="p-relative container mt-3 mt-lg-5 d-flex justify-content-center gap-3 flex-wrap">
        <Button content={<>Comandă acum</>} width="400px" type="full" classes="" link="/#pachete"></Button>
        <Button content={<>{contactPhoneNumbers[0]}</>} width="400px" type="outline" classes="" link={`tel:${contactPhoneNumbers[0].replaceAll(" ", "")}`}></Button>
      </div>

      <div className="p-relative mt-3 mt-lg-5">
        <Footer />
      </div>
    </div>
  );
}

export default DiplomeDeAbsolvire;
